* 
  -webkit-box-sizing: border-box
  -moz-box-sizing: border-box
  box-sizing: border-box

ul 
  margin: 0
  padding: 0

body 
  position: relative
  margin: 0
  font-family: 'Open Sans', sans-serif
  font-weight: 400
  font-size: 16px

a 
  color: #6161a7
  text-decoration: none
  cursor: pointer

a:hover,
a:focus 
  text-decoration: underline
  outline: 0

p, ul 
  line-height: 30px
  list-style-type: none

p 
  margin: 25px 0

li 
  margin: 22px 0

h4 
  margin: 6px 0
  display: inline-block
  color: #007bb9
  font-size: 17px

.hide
  display: none

.linkBtn
  font-size: inherit
  border: 0
  padding: 0
  color: #007bb9
  background: transparent
  cursor: pointer
  &:hover, 
  &:focus
    text-decoration: underline
    outline: 0

.mainInnerWrap 
  margin: 0 auto
  max-width: 1380px
  padding: 0 75px

.mainOuterWrap 
  max-width: 1380px
  padding-left: 20px
  padding-right: 20px

.maxWidthWrap 
  margin: 0 auto
  max-width: 1280px

.pageMarginTop 
  margin-top: 75px

.primaryBtn 
  padding: 13px 80px
  background: #007bb9
  border: 0
  margin: 50px 15px 15px
  color: white
  font-size: 18px
  transition: background .2s
  text-transform: uppercase
  border-radius: 5px
  cursor: pointer
  font-family: inherit
  min-width: 240.63px
  
.primaryBtn:hover,
.primaryBtn:focus 
  background: #0388cc
  text-decoration: none
  outline: 0

.secondaryBtn 
  padding: 14px 86px
  border: 0
  margin: 0
  background: transparent
  color: #007bb9
  font-size: 18px
  text-transform: uppercase
  cursor: pointer

.secondaryBtn:hover,
.secondaryBtn:focus 
  text-decoration: underline

.pageTitleWrap 
  background: #007bb9
  height: 94px

.pageTitle 
  display: inline-block
  color: white
  font-size: 34px
  font-weight: 300
  margin: 24px 0

.subHeading 
  margin: 55px 0 30px
  font-size: 22px
  color: #007bb9

.about,
.contact,
.FAQ,
.privacy, 
.use 
  padding-bottom: 220px

.validation_msg
  color: red

.privacy .subHeading 
  font-size: 20px
  font-weight: 600

.use .openMessage 
  font-weight: 100
  font-size: 20px
  color: #007bb9
  
.link-btn 
	border: 0
	background: transparent
	display: inline
	padding: 0
	font-size: 16px
	font-weight: 400
	font-family: Open Sans,sans-serif
	color: #007bb9
	cursor: pointer
.link-btn:focus,.link-btn:hover 
	text-decoration: underline