.summarySheet .subHeading.justForPrint
    display: none

@media print
  .summarySheet .subHeading.justForPrint
    display: block
    margin: 0
  .noPrint
    display: none !important
  .print
    font-size: 10px
    color: black
  .summarySheet .subHeading
    color: black
  .infoWrap
    font-size: 10px
    line-height: 1.5
  .pageMarginTop
    margin-top: 0
  .pageTitle
    font-size: 24px
    margin: 0
  .selectOptionTypeForm
    margin: 0 auto 0
  .optOutMailInForm 
    margin-bottom: 0
    p
      margin: 0 0 20px
      line-height: 20px
    li
      line-height: 20px
  .letterBody
    margin: 25px 0
  .optOutMailInForm li
    margin: 0