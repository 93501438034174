
.footer 
  background: #007bb9
  color: white
  padding: 15px 0
  font-size: 14px
  letter-spacing: 0
  text-align: center
  -ms-flex-pack: center
  justify-content: center

.footerLink 
  text-decoration: none
  background: transparent
  border: 0
  cursor: pointer
  color: #fff
