.modalOverlay 
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  display: none
  z-index: 1001

.backdrop
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  background: #00000070

.modalWrap
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  display: flex
  justify-content: center
  align-items: center
  z-index: 1

.modalDialogBox 
  background: white
  padding: 50px 75px
  display: flex
  flex-direction: column
  align-items: center
  box-shadow: 0px 8px 30px 0px #656565
  z-index: 2

.modalDialogBox p 
  text-align: center
  margin: 30px 0 40px
  line-height: 1.7

.modalDialogBox .btnWrap 
  margin-bottom: 0

.modalDialogBox .primaryBtn 
  text-align: center;
  font-size: 18px
  padding: 16px
  width: 210px
  margin: 0 15px