#backToTopBtn 
  position: absolute
  bottom: 145px
  right: 12px
  width: 50px
  height: 50px
  background: #0003
  border-radius: 50%
  display: flex
  align-items: center
  cursor: pointer
  transition: background .3s

#backToTopBtn:hover 
  background: #0000004d

#backToTopBtn:before,
#backToTopBtn:after 
  content:''
  display: block
  background: white
  width: 2px
  height: 12px
  position: absolute
  top: calc(50% - 6px)

#backToTopBtn:before 
  left: 20px
  transform: rotate(45deg)

#backToTopBtn:after 
  right: 20px
  transform: rotate(-45deg)

header 
  display: flex
  justify-content: space-between
  padding: 45px 0
  margin: 0 auto

.siteTitle 
  color: #97322b
  margin: 0
  margin-top: 3px
  font-family: sans-serif
  font-size: 36px

.siteTitle span 
  color: #0278b3
  font-family: 'Georgia', Times, serif

nav
  width: 100%
  display: flex
  color: grey
  justify-content: flex-end
  align-items: end

.navLinks 
  font-size: 18px
  text-align: center
  padding: 10px
  display: inline-block
  text-decoration: none
  color: #383838
  text-transform: capitalize
  margin: 0 10px
  font-weight: 400
  background: transparent
  line-height: 1.5
  border: 0
  cursor: pointer
  
.navLinks:hover 
  color: #007bb9
  text-decoration: none

.navLinks:active,
.navLinks.active 
  color: #007bb9

a.navLinks:last-child 
  margin-right: 0
  padding-right: 0