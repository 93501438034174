.summarySheet 
  background: #f8f8f8
  padding: 0 55px 40px
  max-width: 1340px

.summarySheet .subHeading 
  display: inline-block

.summarySheet .label 
  min-width: 160px
  display: inline-block
  font-weight: 400

.summarySheet .userData 
  font-weight: 100

.summarySheet .row 
  margin: 8px 0

.mainInnerWrap.endSession .primaryBtn 
  margin: 35px 0 0
  padding: 13px 85px
  font-size: 16px
  display: inline-block
  letter-spacing: .5px

.mainInnerWrap.endSession
  padding: 0 75px
  margin-bottom: 160px

.optOutMailInForm
  font-family: times
  margin-bottom: 190px
  font-size: 14px
  li
    margin: 5px 0

.toAddress
  li
    margin: 0

.fromAddress
  line-height: 22px

.letterBody
  margin: 20px 0
  > p
    line-height: 22px

.printPageLogos
  display: flex
  justify-content: space-between
  align-items: center
  > a
    width: 115px
    img
      width: 100%

.address-qrCode-wrap
  display: flex
  justify-content: space-between
  align-items: center
  width: 330px
  
.address-qrCode-wrap,.footer 
  display: -ms-flexbox
  display: flex