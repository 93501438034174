.FAQ 
  margin: 0
  color: #007bb9

.hideSeekTab 
  cursor: pointer
  border-bottom: 1px solid #f6f3f399
  background-color: white
  text-align: center
  transition: border .2s

.hideSeekTab:hover 
  border-bottom: 1px solid #e3dede

.hideSeekTab:hover .line 
  background: #007bb9

.hideSeekTab.open .title 
  font-weight: 400

.hideSeekTab.active 
  background: #effcfc

.labelBox 
  position: relative
  display: flex
  padding: 0
  align-items: center
  justify-content: space-between

.iconBox 
  position: relative
  width: 40px
  height: 40px
  transition: transform .3s

.line 
  margin: auto
  display: block
  width: 13px
  height: 2px
  background: #e3e3e3
  position: absolute
  top: 20px
  transition: background .2s ease-out

.left 
  left: 10px
  transform: rotate(45deg)

.right 
  right: 9px
  transform: rotate(-45deg)

.iconBox.closed 
  transform: rotate(180deg)

.title 
  width: calc(100% - 40px)
  text-align: left
  font-weight: 200
  color: #007bb9

.text 
  width: calc(100% - 40px)
  font-weight: 200
  color: #1d1d1b
  display: none
  padding: 0
  text-align: left
  line-height: 1.5
  opacity: 0
  transition: opacity .7s

.text.open 
  opacity: 1
  display: block