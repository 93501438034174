
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600');

// common parts
@import 'global'
@import 'header'
@import 'datepicker'
@import 'forms'
@import 'modal'

// pages
@import 'home'
@import 'faq'
@import 'contact'
@import 'error'
@import 'formselection'
@import 'confirmation'

@import 'footer'

@import 'print'