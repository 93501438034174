.formSheet.mainOuterWrap 
  margin: 50px auto 170px
  max-width: 1380px
  padding: 0 20px

.basicInfo 
  position: relative

.datePickerWrap
  border-radius: 3px
  background: white
  display: flex
  align-items: center
  justify-content: space-between

#datepicker 
  width: calc(100% - 40px)

#svgCalendar
  width: 40px
  height: 44px
  padding: 8px
  border: 1px solid #c1c1c1
  border-left: 0
  border-top-right-radius: 3px
  border-bottom-right-radius: 3px

.requiredLegend 
  display: inline-block
  position: absolute
  right: 0
  top: 45px
  font-style: italic
  color: #007bb9

.formSheet .subHeading 
  display: inline-block
  margin: 40px 0 35px

.formSheet .mainOuterWrap 
  padding-left: 0
  padding-right: 0

.formSheet .mainInnerWrap 
  background: #f8f8f8
  border: 1px solid #d6d6d6
  border-radius: 2px
  padding: 0 50px 55px

.inputsWrap 
  display: flex
  flex-direction: row
  flex-wrap: wrap

.formSheet .textInputWrap
  position: relative
  display: flex
  flex-direction: column
  margin: 0 0 35px
  width: calc(50% - 20px)

.textInputWrap:nth-child(odd) 
  margin-right: 20px

.textInputWrap:nth-child(even) 
  margin-left: 20px

.formSheet .textInputWrap .creditNumWrap 
  display: flex
  justify-content: space-between

.formSheet .textInputWrap .creditNumWrap .textInput 
  width: 32%

.inputError
  margin: 0
  color: #A42724
  font-size: 12px
  position: absolute
  bottom: -30px
  right: 0
  display: none

.formSheet .textInputWrap.hasErrors
  margin-bottom: 48px;
  input, select
    border: 1px solid #A42724
    box-shadow: 0px 0px 3px #a42724
    &:focus
      border: 1px solid #c1c1c1
      box-shadow: 0 0 3px 0px dodgerblue
  .inputError
    display: inline

 .requiredError
  margin: 0
  color: #A42724
  font-size: 12px
  position: absolute
  bottom: -30px
  right: 0
  display: none

.formSheet .textInputWrap.reqErrors
  margin-bottom: 48px;
  input, select
    border: 1px solid #A42724
    box-shadow: 0px 0px 3px #a42724
    &:focus
      border: 1px solid #c1c1c1
      box-shadow: 0 0 3px 0px dodgerblue
  .requiredError
    display: inline

.formSheet .inputLabel 
  display: block
  margin-bottom: 0

.formSheet .inputLabel.column
  display -ms-flexbox 
  display: flex
  -ms-flex-direction column
  flex-direction: column

.formSheet .inputLabel.extra 
  margin-bottom: 10px

.formSheet .inputLabel.info 
  font-size: 80%
  color: grey
  margin: 3px 0 5px

.formSheet .inputLabelhead 
	display: block
	margin-bottom: 0
	
.formSheet .inputLabelhead.column 
	display: -ms-flexbox
	display: flex
	-ms-flex-direction column
	flex-direction: column
		
.formSheet .inputLabelhead.extra 
	margin-bottom: 10px
		
.formSheet .inputLabelhead.info 
	font-size: 80%
	color: gray
	margin: 3px 0 5px
			
.formSheet .inputLabelsub 
	display: block
	margin-bottom: 10px
	
.formSheet .inputLabelsub.column 
	display: -ms-flexbox
	display: flex
	-ms-flex-direction column
	flex-direction: column
		
.formSheet .inputLabelsub.extra 
	margin-bottom: 10px
	
.formSheet .inputLabelsub.info 
	font-size: 80%
	color: gray
	margin: 3px 0 0
		
.formSheet .textInput 
  font-size: 16px
  padding: 12px
  border: 1px solid #c1c1c1
  width: 100%
  border-top-left-radius: 3px
  border-top-right-radius: 3px
  border-bottom-right-radius: 3px
  border-bottom-left-radius: 3px
  &#datepicker
    border-top-right-radius: 0
    border-bottom-right-radius: 0

.formSheet .textInput:focus 
  appearance: none
  -webkit-appearance: none
  -moz-appearance: none
  outline: none
  border: 1px solid #c1c1c1
  box-shadow: 0 0 3px 0px dodgerblue

.formSheet select.textInput 
  appearance: none
  -webkit-appearance: none
  -moz-appearance: none
  background: white

.formSheet select.textInput::after 
  content: ''
  display: inline-block
  background-image: url("data:image/svg+xml,%3Csvg width='18px' height='22px' viewBox='0 0 18 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 46.2 %2844496%29 - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3EArrows%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='3_Form---Opt-In' transform='translate%28-895.000000, -955.000000%29'%3E%3Cg id='Form' transform='translate%2845.000000, 489.000000%29'%3E%3Cg id='Basic-Information' transform='translate%2862.000000, 45.000000%29'%3E%3Cg id='DOB' transform='translate%28638.000000, 327.000000%29'%3E%3Cg id='Month' transform='translate%280.000000, 77.000000%29'%3E%3Cg id='Arrows' transform='translate%28139.000000, 2.000000%29'%3E%3Cg id='ic_arrow_drop_down-copy' transform='translate%280.000000, 12.000000%29'%3E%3Cg id='Icon-24px'%3E%3Cpolygon id='Shape' fill='%23000000' points='11.6666667 16.6666667 20 25 28.3333333 16.6666667'%3E%3C/polygon%3E%3Cpolygon id='Shape' points='0 0 40 0 40 40 0 40'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3Cg id='ic_arrow_drop_up-copy'%3E%3Cg id='Icon-24px'%3E%3Cpolygon id='Shape' fill='%23000000' points='11.6666667 23.3333333 20 15 28.3333333 23.3333333'%3E%3C/polygon%3E%3Cpolygon id='Shape' points='0 0 40 0 40 40 0 40'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")

.inputLabel.required:after 
  content: '*'
  color: #007bb9
  display: inline-block

.formSheet select.textInput 
  background-image: linear-gradient(45deg, transparent 50%, black 50%), linear-gradient(135deg, black 50%, transparent 50%)
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em
  background-size: 5px 5px, 5px 5px, 1px 1.5em
  background-repeat: no-repeat

select:focus 
  background-image:
    linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc)
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em
  background-repeat: no-repeat
  border-color: green
  outline: 0

select:-moz-focusring 
  color: transparent
  text-shadow: 0 0 0 #000

.formSheet .columnWrap 
  display: flex

.formSheet .column.left 
  margin-right: 20px
  transform: none

.formSheet .column.right 
  margin-left: 20px
  transform: none

.SecurityWrap 
  display: flex
  align-items: center

.securityTooltip 
  position: relative
  width: 18px
  height: 18px
  border-radius: 50%
  background: #007bb9
  color: white
  margin: 6px 0 0 8px
  display: flex
  justify-content: center
  align-items: center
  font-size: 11px
  font-weight: 600

.securityTooltip:focus,
.securityTooltip:hover 
  outline: 0

.toolTipMessage 
  opacity: 0
  position: absolute
  background: #00000080
  padding: 10px
  border-radius: 6px
  border-bottom-left-radius: 0
  font-weight: 100
  bottom: 30px
  left: 8px
  width: 210px
  transition: all .4s ease
  pointer-events: none
  transform: translateY(4px)

.toolTipMessage:after 
  content: ''
  position: absolute
  bottom: -8px
  left: -1px
  width: 0px
  height: 0px
  border-top: 6px solid transparent
  border-bottom: 6px solid transparent
  border-right: 6px solid #00000080
  transform: rotate(45deg)

.securityTooltip:hover .toolTipMessage,
.securityTooltip:focus .toolTipMessage 
  opacity: 1
  transform: translateY(0)

.confirmCancelWrap 
  margin: 40px 0 0

.confirmWrap,
.cancelWrap 
  display: flex
  flex-direction: row
  align-items: center

.confirmWrap .primaryBtn 
  margin: 0

.buttonMessaging 
  margin-left: 50px

#ssn_error
  line-height: 15px
  text-align: right
  margin-bottom: -5px

.captchaInputWrap
  position: relative
  width: calc(50% - 20px)
  .error
    display: none

.formSheet span.error~input
  border: 1px solid #a42724
  box-shadow: 0 0 3px #a42724
  ~ .captchaError
    display: block;
    
.formSheet.textInputWrap.hasFormErrors
  margin-bottom: 48px
  input, select
    border: 1px solid #A42724
    box-shadow: 0px 0px 3px #a42724
    &:focus
      border: 1px solid #c1c1c1
      box-shadow: 0 0 3px 0px dodgerblue
    margin: 0
    color: #A42724
    font-size: 12px
    position: absolute
    bottom: -30px
    right: 0
    display: inline